import { AnchorLink } from 'gatsby-plugin-anchor-links'
import * as React from 'react'
import whatsAppImg from '../assets/img/whatsapp.svg'
import mailImg from '../assets/img/mail.svg'
import commerSightLogoW from '../assets/img/commersight-logo-w.svg'
import { Link } from 'gatsby'

export const Footer = (): React.ReactElement => {
	return (
		<>
			<section className="back-sky" id="contact">
				<div className="container-body">
					<div className="container-contact">
						<div className="contact-title">
							<span className="contact-title-txt">¿Tienes más dudas?</span>
						</div>
						<div className="phone">
							<a href="https://wa.me/5215531499248" className="whatsLink">
								<div className="phone-title">
									<div className="whats">
										<img src={whatsAppImg} alt="whatsapp" />
									</div>
									<div className="phone-txt">
										<span>
											Escribenos por whatsapp
											<br />
											Lunes a viernes
										</span>
									</div>
								</div>
								<div className="divider-white"></div>
								<div className="contact-txt">
									<span>+52 1 55 3149 9248</span>
								</div>
							</a>
						</div>
						<div className="phone">
							<div className="phone-title">
								<div className="whats">
									<img src={mailImg} alt="mail" />
								</div>
								<div className="phone-txt">
									<span>
										Envianos un
										<br />
										correo electrónico
									</span>
								</div>
							</div>
							<div className="divider-white"></div>
							<div className="contact-txt">
								<span>support@commersight.com</span>
							</div>
						</div>
					</div>
				</div>
			</section>

			<footer className="footer">
				<div className="container-footer">
					<div className="logo-footer">
						<img className="logo-footer-size" src={commerSightLogoW} alt="Commersight" />
						<span className="text-footer">Incrementando los ingresos de tu ecommerce</span>
					</div>
					<div className="legals">
						<span className="text-footer">
							Copyright © 2014- 2021. Todos los derechos reservados.
							<br />
							<Link to="/aviso-privacidad" target="_self">
								Términos y Condiciones
							</Link>
							.{' '}
							<Link to="/aviso-privacidad#privacidad" target="_self">
								Política de Privacidad
							</Link>
							.
						</span>
					</div>
				</div>
			</footer>
		</>
	)
}
