import { AnchorLink } from 'gatsby-plugin-anchor-links'
import * as React from 'react'

export const Header = (): React.ReactElement => {
	return (
		<>
			<header className="header">
				<div className="container">
					<div className="logo">
						<AnchorLink to="/">
							<img className="logo-link" alt="Commersight" />
						</AnchorLink>
					</div>
					<div className="menu">
						<ul>
							<li>
								<AnchorLink className="menu-link" to="/#home">
									Inicio
								</AnchorLink>
							</li>
							<li>
								<AnchorLink className="menu-link" to="/#function">
									Funcionalidad
								</AnchorLink>
							</li>
							<li>
								<AnchorLink className="menu-link" to="/#pricing">
									Precios
								</AnchorLink>
							</li>
							<li>
								<AnchorLink className="menu-link" to="/#knowus">
									Conócenos
								</AnchorLink>
							</li>
							<li>
								<AnchorLink className="menu-link" to="/#contact">
									Contacto
								</AnchorLink>
							</li>
						</ul>
						<a href="https://admin.commersight.com/" target="_blank" className="btn-login">
							Ingresar
						</a>
					</div>
					<div className="menu-burguer">
						<input type="checkbox" id="menu" />
						<label htmlFor="menu">☰</label>
						<ul>
							<li>
								<AnchorLink className="menu-link" to="/#home">
									Inicio
								</AnchorLink>
							</li>
							<li>
								<AnchorLink className="menu-link" to="/#function">
									Funcionalidad
								</AnchorLink>
							</li>
							<li>
								<AnchorLink className="menu-link" to="/#pricing">
									Precios
								</AnchorLink>
							</li>
							<li>
								<AnchorLink className="menu-link" to="/#knowus">
									Conócenos
								</AnchorLink>
							</li>
							<li>
								<AnchorLink className="menu-link" to="/#contact">
									Contacto
								</AnchorLink>
							</li>
							<li>
								<a href="https://admin.commersight.com/" target="_blank" className="menu-link">
									Ingresar
								</a>
							</li>
						</ul>
					</div>
				</div>
			</header>
			<div className="free">
				<span className="promo">¡Tus primeras 50 facturas gratis!</span>
			</div>
		</>
	)
}
