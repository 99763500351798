/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { ReactNodeArray } from 'react'

import { Header } from './header'
import { Footer } from './footer'
import ScrollToTop from './scroll-to-top'

interface LayoutProps {
	children: ReactNodeArray
}

const Layout = ({ children }: LayoutProps): React.ReactElement => {
	return (
		<>
			<Header />
			<main>{children}</main>
			<ScrollToTop />
			<Footer />
		</>
	)
}

export default Layout
